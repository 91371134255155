.register-list{
    display: none;
}
@media (max-width: 700px) {
    .register-table {
        display: none;
    }
    .register-list{
        display: block;
    }
}