@keyframes fadeInLeft {
    0% {
        opacity: 0;
        filter:blur(0px);
    }
    20% {
        opacity: 1;
        filter:blur(1.25px);
    }
    100%{
        filter: blur(5px);
    }
}

@keyframes fadeInText {
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.fadeIn-1 {
    animation: fadeInLeft 4s;
    filter: blur(5px);
    background: url(../images/make.jpg);
}
.fadeIn-text{
    animation: fadeInText 4s;
}