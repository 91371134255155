@keyframes bounce {
    0%{
        transform: translateY(-7px);
    }
    100%{
        transform: translateY(7px);
    }
}

.detail{
    animation-name: bounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.aos-init:not(.aos-animate):after {
    position: fixed;
}